import React from "react"
import styles from './CTAButton.module.css'

const CTAButton = ({ title, className, overloadClick, ...buttonProps }) => {
    const onClick = () => {
        const el = document.getElementById('contact')

        if (el) {
            window.scrollTo({ top: el.offsetTop - 100 })
        }
    }

    return (
        <button aria-label={title} className={`${styles.button} ${className}`} {...buttonProps} onClick={overloadClick ? overloadClick : onClick}>
            {title}
        </button>
    )
}

export default CTAButton
